// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "./remote"
import './extend'
import './plugins/global'
import Vue from "vue";
import VueBus from "vue-bus";
import App from "./App";
import store from "./store/store";
import router from "./router/router";
import seoSetting from './module/seoSetting'
import { IEVersion } from "./config/env";
import "./config/screen";
import "./config/group";
// import "babel-polyfill";
import { Message, Tag, Loading, Tooltip as elTooltip, Popover as elPopover, InputNumber as elInputNumber } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import './style/element-variables.scss'
import { setStore } from "./module/store";
import { setCookie, getCookie } from "./module/cookie";
import { mobile_device_detect, isMobile } from "./module/util";
import { nowDomain, vconsole, imgBaseUrl, wechatAppId } from "./config/env";
import VueLazyload from 'vue-lazyload'
import $href from './module/href'
import statistics from './plugins/statistics'
import directives from './directives'
import notification from './plugins/notification'
import globPlugin from './plugins'
import {Button, Row, Col, Switch, Form, Icon, Modal, Rate, Radio, Select, TreeSelect, Input, Empty, Slider, InputNumber, Tooltip, Spin, Tabs as aTabs, Collapse as aCollapse, Carousel as aCarousel, Progress, Menu, Alert, Card, Checkbox, DatePicker, Table, AutoComplete, Pagination,LocaleProvider,FormModel, Drawer, Dropdown, Badge} from 'ant-design-vue';

// import 'ant-design-vue/dist/antd.css';
import getGuid from './module/guid'
import { copy, focus, inputNumber } from '@alltuu/directives'
import { notification as tipMes } from '@alltuu/alltuu-ui'
import { thirdBridgeVue } from '@alltuu/thirdBridge'
import { aiTrack } from "./module/aiTrack"
import vcolorpicker from 'vcolorpicker'
import VTooltip from 'v-tooltip';
store.dispatch("UPDATE_GS_GRAYINFO")
Vue.use(VTooltip);
Vue.use(vcolorpicker)
const { newWechatConfigApi } = require('./service/newGetData')



window.tipBox = window.$tipMes = Vue.prototype.$notify = Vue.prototype.$tipMes = tipMes

/* =============生成或获取guid================ */
const guid = getCookie('guid')
if (guid) {
  window.guid = guid
} else {
  window.guid = getGuid()
  setCookie('guid', window.guid, 3000)
}


Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Switch)
Vue.use(Form)
Vue.use(Icon)
Vue.use(Modal)
Vue.use(Rate)
Vue.use(Radio)
Vue.use(Select)
Vue.use(TreeSelect)
Vue.use(Input)
Vue.use(Empty)
Vue.use(Slider)
Vue.use(InputNumber)
Vue.use(Tooltip)
Vue.use(Spin)
Vue.use(aTabs)
Vue.use(aCollapse)
Vue.use(aCarousel)
Vue.use(Progress)
Vue.use(Menu)
Vue.use(Alert)
Vue.use(Card)
Vue.use(Checkbox)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(AutoComplete)
Vue.use(Pagination)
Vue.use(LocaleProvider)
Vue.use(FormModel)
Vue.use(Drawer)
Vue.use(Dropdown)
Vue.use(Badge)
Vue.use(VueBus)


// 当前运行环境  "alltuu" 和  "guituu" 环境
Vue.prototype.$href = $href
if( vconsole ) {
  new vconsoleObj()
}
Vue.use(Tag)
Vue.use(elTooltip)
Vue.use(elPopover)
Vue.use(elInputNumber)
Vue.use(directives)
Vue.use(statistics)
Vue.use(notification)
Vue.use(globPlugin)
Vue.use(VueLazyload, {
  error: imgBaseUrl,
  loading: imgBaseUrl + '/loading-default.png',
  attempt: 1
})
Vue.config.productionTip = false;

Vue.use(Loading.directive);

// 文档
// https://element.eleme.cn/#/zh-CN/component/loading
Vue.prototype.$eleLoading = (option = {}) => {
  const defaultOption = {
    // lock: true,
    text: '',
    // spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  }
  return Loading.service(Object.assign(defaultOption, option))
} ;

Vue.prototype.$aiTrack = aiTrack


window.$userInfo = () => {
  return store.getters.getUserInfo
}
window.$login = () => {
  return location.href = `https://www.${nowDomain}.com/login/?url=https://www.${nowDomain}.com/`
}
window.$notify = Message
window.__HOST_FROM__ = process.env.VUE_APP_SIGNATURE_FROM

Vue.prototype.$message = Message;

setStore("baseUrl", location.href);

let ieVersion = IEVersion(); //判断是否是IE浏览器

if (ieVersion < 10 && ieVersion > 5) {
  window.location.href = "https://h.alltuu.com/pcUpdate.html";
}

Vue.directive('focus', focus)
Vue.directive('inputNumber', inputNumber)
Vue.directive('copy', copy)

if ( !isMobile()  && 'serviceWorker' in navigator) {
  navigator.serviceWorker
  .register('/sw.js')
  .then(() => { console.log('Service Worker Registered'); });

  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    window.deferredPrompt = e;
  });
}


// 上报来源
if(document.referrer) {
  window.$track.add({
    topic: 'PC来源',
    name: document.referrer,
    localHref: location.href
  })
}

/* ============添加第三方（微信、钉钉）插件============== */
Vue.use(thirdBridgeVue, {
  debug: location.href.includes('debug=true'),
  getSignature: async url => {
    const res = await newWechatConfigApi(url, wechatAppId)
    if (res.errorCode === 0) {
      return res.data.config
    }
    throw new Error('wechat config 接口请求错误')
  }
})



//路由判断是否需要登陆
router.beforeEach((to, from, next) => {
  //带有/v/的路由重定向到新路由
  if(to.fullPath.indexOf("/v/") > -1){
    let newpath = to.fullPath.substr(2)
    location.href = location.origin + newpath
  }

  // 配置走seo的配置
  if (to.meta.canNotLogin) {
    seoSetting(to)
  }

  //在新闻页面添加相应的css样式表
  if (['about-newsinfo'].includes(to.name)) {
    let link = document.createElement("link")
    link.href = "https://sr.alltuu.com/admin/css/quill.snow.css"
    link.rel = "stylesheet"
    const head = document.querySelector("head")
    head.appendChild(link)
  }


//  ---------------------- 自动添加 / 逻辑 -----------------------------
  // 正则处理路径末尾自动加 '/'
  // const targetPath = to.fullPath
  // const queryIndex = targetPath.indexOf('?')
  // // 如果带问号
  // if( queryIndex > 3 ) {
  //   if( !/.*\/[?].*/.test(targetPath) ) {
  //     const hrefStrArr = targetPath.split('')
  //     if( hrefStrArr[queryIndex - 1] !== '/' ) {
  //       hrefStrArr.splice(queryIndex, 0, '/')
  //       next(hrefStrArr.join(''))
  //     }
  //   }
  // }

  // 结尾自动加 / 的链接，暂时不加
  // 与 OSS 静态托管冲突，OSS 托管的网页，以 / 结尾为文件夹，不是文件，无法访问回进到 404 逻辑中
  // const handleUrl = ["/cases", "/videoCases", "/mVideoCases", "/about/news", "/news", "/alltuuPretty" , "/index"]
  // if(handleUrl.indexOf(to.path) > -1){
  //   next(to.path + "/")
  // }

  let isMatch = to.matched.some(record => {
    if (record.meta.toMoblie) {
      mobile_device_detect(
        `${process.env.VUE_ANTI_BAN_DOMAIN}/review/?id=${to.query.id}`
      );
    }
    // 路由是从登陆页过来的话，查一下新手任务
    if(['passwordLogin', 'newRegister', 'verificationLogin'].includes(from.name) && getCookie("cookieUserInfoV1")) {
      store.dispatch('SET_NOVICETASKLIST')
      store.dispatch('SET_STORAGEINFO')
      store.dispatch('SET_IS_NETDISK')
    }
    // record.meta.requiresAuth && isMobile() != true
    if (record.meta.requiresAuth) {
      if (getCookie("cookieUserInfoV1")) {
        store.commit("SAVE_USERINFO", JSON.parse(getCookie("cookieUserInfoV1")));
        next();
      } else {
        removeAllCookie();
        if (record.name == "auditing") {
          window.location.href = `https://www.${nowDomain}.com/login/?url=https://www.${nowDomain}.com${to.fullPath}`;
          return;
        }
        alert({
          tit: "提醒",
          width: "400px",
          showClose: true,
          con: `
            <div style="text-align:center;font-size:18px;">
                请先登录
            </div>
          `
        });
        window.location.href = `https://www.${nowDomain}.com/login/?url=https://www.${nowDomain}.com${to.fullPath}`;
        next({
          path: "/"
        });
      }
    }
    if (record.meta.canNotLogin) {
      if (getCookie("cookieUserInfoV1")) {
        store.commit("SAVE_USERINFO", JSON.parse(getCookie("cookieUserInfoV1")));
      }
      next();
    }
  });
  if (!isMatch) {
    next();
  }
});
var _hmt = window._hmt || [];
// 登陆过的话，查一下新手任务
if(getCookie("cookieUserInfoV1")) {
  store.dispatch('SET_NOVICETASKLIST')
  store.dispatch('SET_STORAGEINFO')
  store.dispatch('SET_IS_NETDISK')
}

router.afterEach(hook => {
  _hmt.push(["_trackPageview", hook.fullPath]);
});
Vue.config.devtools =  process.env.VUE_APP_ENV !== 'production'
document.addEventListener("DOMContentLoaded", function () {
  window.alltuuApp = new Vue({
    el: "#app",
    store,
    router,
    template: "<App/>",
    components: {
      App
    }
  }).$mount("#app");
});
